import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";
import "./common/common.css";
import Cookies from "js-cookie";

import router from "./router";
import axios from "axios";
import Swiper from "swiper"; // 导入Swiper库
import "swiper/css/swiper.css"; // 导入Swiper样式文件

import { publicUrl } from "@/common/publicUrl.js";

import msg from "@/common/Message";


// import store from "./store/store";

// 1.安装插件

Vue.use(router);
Vue.use(ElementUI);
axios.defaults.baseURL = "https://api-wx.qtthtech.com/web/"; // 线上
// axios.defaults.baseURL = "http://testapi-wx.qtthtech.com/web/"; // 测试
// axios.defaults.baseURL = "http://109.244.44.219:8087/web/"; // 本地
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    let token = Cookies.get("wx_token");
    if (token) {
      config.headers.token = token;
    }
    config.metadata = { startTime: new Date() }; //添加代码，记录请求的开始时间
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    response.config.metadata.endTime = new Date(); //记录请求的结束时间
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    // 对响应数据做点什么
    return response;
  },
  function (err) {
    if (err && err.response) {
      if (err.response.status == 402) {
        Vue.prototype.$message.error("登录信息过期，请重新登陆");
        Cookies.set("wx_userId", "", { domain: ".qtthtech.com" });
        Cookies.set("wx_token", "", { domain: ".qtthtech.com" });
      }
    } else {
      Vue.prototype.$message.error("当前系统开了小差，请重试！");
    }
    return Promise.reject(err);
  }
);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  // console.log(to, to.query, to.fullPath);
  document.title = to.meta.title; //切换路由改变页面标题
  let postfix1 = "";

  if (Object.keys(to.query).length > 1) {
    let postfix = to.fullPath.split("&s=");
    // if (postfix.length > 1) {
    postfix1 = postfix[0];
    // console.log(postfix1, "postfix1");
    // }
  } else if (to.query.s) {
    postfix1 = to.path;
  } else {
    postfix1 = to.fullPath;
  }

  // console.log(postfix1,'postfix1');
  const whether = to.query.s ? to.query.s : "";
  // console.log(whether,'whether');
  if (whether) {
    if (!localStorage.getItem(whether)) {
      location.reload();
    }
    if (whether == "17173") {
      localStorage.removeItem("9i173");
    } else if (whether == "9i173") {
      localStorage.removeItem("17173");
    }
    localStorage.setItem(whether, whether);
    next({
      path: postfix1,
    });
    // next()
    // console.log(to);

    return;
  }
  // document.title = to.meta.title; //切换路由改变页面标题
  if (to.meta.isMustLogin) {
    if (Cookies.get("wx_token")) {
      next();
    } else {
      Vue.prototype.$message.error("请先登录！");
      if (from.name == null) {
        window.location.href = "https://wx.qtthtech.com";
      }
    }
  } else {
    if (to.name == "Account") {
      if (Cookies.get("wx_token")) {
        next();
      } else {
        location.href = publicUrl.loginPage;
      }
    } else if (to.name == "Register") {
      if (Cookies.get("wx_token")) {
        Vue.prototype.$message.error("请先退出当前账户");
      } else {
        next();
      }
    } else {
      next();
    }
  }
});
Vue.prototype.resetSetItem = function (key, newVal) {
  // 原型上定义一个存储userInfo 的事件，用户监听用户信息，以便于实时更新用户信息
  if (key === "userInfo") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};
Vue.prototype.$message = {
  // 加载
  loading: (message) => {
    msg(message, "loading");
  },
  // 成功
  success: (message) => {
    msg(message, "success");
  },
  // 失败
  error: (message) => {
    msg(message, "error");
  },
  // 警告
  warning: (message) => {
    msg(message, "warning");
  },
  // 
  info: (message) => {
    msg(message, "info");
  },
  // 关闭
  close: () => {
    MessageInstanse.closeMessage();
  },
};
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
